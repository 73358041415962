@font-face {
  font-family: 'Apfel Grotezk';
  src: url('./fonts/ApfelGrotezk-Regular.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fragment Mono';
  src: url('./fonts/FragmentMono-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@keyframes zoomOut {
  from {
    opacity: 0;
    transform: scale(1.4); /* Commence avec l'image 30% plus grande que la normale */
  }
  to {
    opacity: 1;
    transform: scale(1); /* Retour à la taille normale */
  }
}

@keyframes slideUp {
  from {
    transform: translateY(5px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.App-header, .feature-section, .email-form, .App-footer {
  animation: slideUp 4s ease-out forwards;
}


.App {
  text-align: center;
  background-color: black;
  color: white;
  height: auto; 
  min-height: 100vh; 
}

h1{
  color: var(--White, #FFF);
  text-align: center;
  font-family: "Apfel Grotezk";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 140% */
  margin-top: 95px;
}

h2{
  color: var(--Yellow, #FBC02B);
  text-align: center;
  font-family: "Fragment Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

h3{
  color: var(--White, #FFF);
  text-align: center;
  font-family: "Apfel Grotezk";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
}

p{
  color: var(--White, #FFF);
  font-family: "Fragment Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.highlight {
  color: var(--Yellow, #FBC02B);
}

.feature-section,
.email-form {
  margin-bottom: 20px;
}

.App-logo,
.feature-logo {
  height: 80px;
  margin-bottom: 20px;
}

.Refractio-logo{
  padding-top: 48px;
  width: 171px;
  height: 34px;
  flex-shrink: 0;
}

.Main-image {
  animation: zoomOut 2.5s ease-out forwards;
  width: 100%; /* Assurez-vous que l'image est responsive */
  max-width: 1200px; /* ou une autre largeur maximale selon le design */
  height: auto; /* Maintient le ratio d'aspect de l'image */
  display: block;
  margin: 20px auto; /* Centrage de l'image */
}

.feature-section {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 140px;
}

.feature {
  display: flex;
  flex-direction: column; /* Organise les enfants verticalement */
  align-items: center; /* Centre les enfants horizontalement */
  justify-content: start; /* Alignement du début pour les textes et images */
  width: 30%;
  padding: 10px;
  box-sizing: border-box;
}

.feature-logo {
  width: auto;  /* Gardez la largeur auto pour maintenir les proportions */
  height: 50px; /* Hauteur fixe pour toutes les images */
  object-fit: cover; /* Assure que l'image couvre l'espace sans déformation */
  margin-bottom: 10px;
}

.email-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Assure que le formulaire prend toute la largeur du conteneur */
  padding: 20px; /* Ajoute un peu d'espace autour du formulaire */
  box-sizing: border-box;
  background-color: black; /* Définit le fond du formulaire en noir */
}

.email-input {
  width: 350px; 
  height: 35px;/* Réduit la taille de l'input à 50% de celle du formulaire */
  flex-grow: 1;
  background-color: transparent; /* Assure que l'arrière-plan de l'input est transparent */
  border: none; /* Enlève toute bordure visible autour de l'input */
  color: #6D6D6D; /* Couleur du texte dans l'input */
  outline: none; /* Supprime l'outline lors de la sélection */
  padding-left: 16px; /* Ajustez selon le besoin pour l'espacement intérieur */
  padding-right: 16px;
  font-size: 18px;
  font-family: 'Apfel Grotezk', sans-serif;
}

.email-input::placeholder {
  color: #6D6D6D; /* Couleur du placeholder */
}

label{
  color: var(--Grey, #6D6D6D);
  font-family: "Apfel Grotezk";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 15px;
}

.join-button {
  background-color: #FFFFFF; /* Couleur de fond blanche avec 70% d'opacité */
  color: #000; /* Couleur du texte noir pour un contraste optimal */
  font-size: 13px; /* Définit la taille de la police à 14px */
  padding: 10px 20px; /* Padding interne pour un meilleur confort visuel */
  border: none; /* Aucune bordure */
  border-radius: 24px; /* Rayon de la bordure arrondie */
  cursor: pointer; /* Style du curseur pour indiquer qu'il s'agit d'un bouton cliquable */
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.5);
  font-family: 'Apfel Grotezk', sans-serif;
  width: 160px;
  height: 40px;
  box-shadow: 0px 0px 15px 1px #FFFFFFB2;
}

.join-button:hover {
  border-radius: 18px;
  background: var(--Yellow, #FBC02B); /* Utilise une variable CSS pour la couleur jaune, avec un fallback */
  box-shadow: 0px 0px 15px 1px #FBC02B;
}

.input-group {
  display: flex;
  justify-content: space-between; /* Écarte les éléments aux deux extrémités */
  align-items: center;
  width: 522px; /* Largeur spécifique du groupe input + bouton */
  margin: 0 auto; /* Centre le groupe */
  border: 1px solid #6D6D6D; /* Bordure pour le groupe */
  border-radius: 24px; /* Rayon de bordure pour l'ensemble du groupe */
  padding: 8px; /* Espace intérieur autour de l'input et du bouton */
  background-color: black; /* Fond noir */
  height: 35px;
}

.App-footer {
  height: 410px; 
  width: 100%; /* Assure que le footer prend toute la largeur */
  background: linear-gradient(#000000, #242424);
  position: relative; /* Permet le positionnement absolu des enfants */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Cache les parties du logo qui dépassent */
}

.footer-logo {
  position: absolute;
  bottom: -2px; /* Conserve la position du logo pour être légèrement coupé */
  left: 50%; /* Centrage horizontal */
  transform: translateX(-50%); /* Ajustement fin pour centrer exactement le logo */
  height: auto; /* Assure que le logo conserve ses proportions */
  max-height: 268px; /* Limite la hauteur maximale pour éviter l'étirement */
  width: auto; /* Assure que le logo ne s'étire pas horizontalement */
}

.footer-content {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Changé de 'space-around' à 'space-between' pour mieux aligner les éléments */
  align-items: center;
  position: absolute;
  bottom: 30px; /* Déplacé ici pour aligner avec le bas du footer */
  left: 0; /* Ajouté pour s'étendre sur toute la largeur */
  right: 0; /* Ajouté pour s'étendre sur toute la largeur */
}

.footer-section {
  min-width: 120px;
  text-align: center;
  margin: 0 40px; /* Augmentez la marge pour rapprocher du centre */
}

.footer-section:first-child {
  text-align: left;
  margin-left: 280px; /* Ajustez cette valeur pour centrer plus vers le milieu */
}

.footer-section:last-child {
  text-align: right;
  margin-right: 280px; /* Ajustez cette valeur pour centrer plus vers le milieu */
}

.footer-link {
  text-decoration: underline;
  color: white;
  cursor: pointer;
}









@media (max-width: 768px) {
  .feature-section {
    flex-direction: column; /* Organise les enfants verticalement */
    align-items: center; /* Centre les enfants verticalement */
  }

  .feature {
    width: 100%; /* Ajuste la largeur pour prendre toute la largeur disponible */
    margin-bottom: 20px; /* Ajoute un peu d'espace entre les éléments */
    box-sizing: border-box; /* Assure que les paddings et borders sont inclus dans la largeur */
  }

  .feature-logo {
    width: 58.43px;
    height: 45.748px;
    flex-shrink: 0;
  }

  h1, h2, h3 {
    text-align: center; /* Assure que tout le texte est centré */
  }

  .email-input, .join-button {
    width: auto; /* Permet aux éléments de s'adapter à la taille du conteneur */
    flex-grow: 1; /* Permet au champ d'input et au bouton de remplir l'espace */
    font-size: 16px; /* Ajuste la taille de la police pour les petits écrans */
  }

  .input-group {
    flex-direction: row; /* Organise les enfants horizontalement */
    padding: 0;
  }

  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Supprime le défilement horizontal partout sur la page */
  }

  .Main-image {
    width: 190vw;
    /* margin-left: -20vw;
    margin-right: -20vw;
    transform: translateX(-50%) */
    position: relative;
    left: -45vw;
    margin: 0;
  }
}

h1{
  color: var(--White, #FFF);
  text-align: center;
  font-family: "Apfel Grotezk";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 100% */
}

h2{
  color: var(--Yellow, #FBC02B);
  text-align: center;
  font-family: "Fragment Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

h3{
  color: var(--White, #FFF);
  text-align: center;
  font-family: "Apfel Grotezk";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
}

p{
  color: var(--White, #FFF);
  text-align: center;
  font-family: "Fragment Mono";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Media queries pour téléphones mobiles */
@media (max-width: 480px) {
  .feature-section {
    flex-direction: column; /* Organise les enfants verticalement */
    align-items: center; /* Centre les enfants verticalement */
  }

  .email-input {
    font-size: 14px; /* Taille de la police adaptée pour les petits écrans */
  }

  .join-button {
    padding: 8px 16px; /* Réduit légèrement le padding pour réduire la taille du bouton */
    font-size: 10.5px; /* Réduit la taille de la police pour une meilleure intégration */
    flex-grow: 0; /* Empêche le bouton de grandir pour prendre plus d'espace */
    flex-basis: 35%; /* Donne au bouton une base de 30% de la largeur de l'input-group */
    height: auto;
    margin-right: 5px;
    box-shadow: 0px 0px 8px 1px #FFFFFFB2;
  }

  .feature {
    width: 90%; /* Réduit légèrement la largeur pour un peu de marge */
    margin: 10px auto; /* Centre et ajoute de l'espace autour de chaque feature */
    box-sizing: border-box; /* Assure que les paddings et borders sont inclus dans la largeur */
  }

  .feature-logo {
    width: 58.43px;
    height: 45.748px;
    flex-shrink: 0;
  }

  .App-footer {
    flex-direction: row; /* Change la direction pour organiser les éléments horizontalement */
    justify-content: center; /* Centre les éléments horizontalement */
    align-items: center; /* Centre les éléments verticalement */
    height: 80px; /* Hauteur fixée comme spécifié */
  }

  .footer-logo {
    max-height: 55px; /* Taille spécifiée pour le logo */
    width: auto; /* Conserve les proportions */
    margin: 0 10px 0 0; /* Ajoute un peu d'espace à droite du logo */
    order: 1; /* Place le logo au début */
  }

  .footer-content {
    display: flex;
    flex-direction: row; /* Garde les éléments du footer en ligne */
    justify-content: center; /* Centre les liens horizontalement */
    align-items: center; /* Alignement vertical au centre */
    width: 100%; /* Utilise la pleine largeur disponible */
    order: 2; /* Place le contenu après le logo */
    margin-left: 6px;
    bottom: 5px;
  }

  .footer-section {
    display: inline; /* Affiche les sections en ligne */
    margin: 0 5px; /* Ajoute de petits espaces horizontaux entre les sections */
    text-align: center; /* Centre le texte */
    font-size: 12px; /* Ajuste la taille de la police pour l'uniformité et la visibilité */
  }

  .footer-link {
    display: inline; /* S'assure que les liens ne prennent que l'espace nécessaire */
  }

  h1{
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Apfel Grotezk";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; 
  }
  
  h2{
    color: var(--Yellow, #FBC02B);
    text-align: center;
    font-family: "Fragment Mono";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  }
  
  h3{
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Apfel Grotezk";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
  }
  
  p{
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Fragment Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}